@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap");

* {
	box-sizing: border-box;
}

html {
	background-image: linear-gradient(to right bottom, #e66465, #e66790, #d675b6, #b887d4, #9198e5);
	height: 100%;
}

body {
	font-family: "Montserrat", sans-serif;
	margin: 0;
}

.centered {
	margin: 3rem auto;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
  }
  